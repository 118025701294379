import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import ScrollToTop from "../Components/ScrollToTop";

const RefundPolicy = () => {
  return (
    <>
      <ScrollToTop />
      <Navbar />

      <div className="bg-gray-100 py-[7rem]">
        <div className="max-w-4xl mx-auto bg-white p-6 shadow-md rounded-lg">
          <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">
            Kitovu Technology Company Refund Policy
          </h1>

          {/* Overview Section */}
          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-700 mb-3">
              1. Overview
            </h2>
            <p className="text-gray-600">
              At Kitovu Technology Company, we are committed to providing our
              customers with quality products and services that meet their
              needs. In the event that our services or products do not meet your
              expectations, this refund policy outlines the conditions under
              which refunds may be issued.
            </p>
          </section>

          {/* Eligibility for Refunds */}
          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-700 mb-3">
              2. Eligibility for Refunds
            </h2>
            <ul className="list-disc pl-6 text-gray-600">
              <li className="mb-2">
                <strong>Product Issues:</strong> If a product purchased through
                our platforms (WeBuyAm, eProcure, ComboTrader) is defective,
                damaged, or not as described at the time of delivery.
              </li>
              <li className="mb-2">
                <strong>Service Issues:</strong> If the services provided (e.g.,
                YieldMax, StorageX, Equipment Leasing) fail to meet the
                specified terms due to a fault or negligence on the part of
                Kitovu.
              </li>
              <li className="mb-2">
                <strong>Order Cancellation:</strong> If a customer cancels an
                order before the product or service has been delivered or
                executed.
              </li>
            </ul>
          </section>

          {/* Non-Refundable Situations */}
          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-700 mb-3">
              3. Non-Refundable Situations
            </h2>
            <ul className="list-disc pl-6 text-gray-600">
              <li className="mb-2">
                If the product or service was received as described and is in
                good condition.
              </li>
              <li className="mb-2">
                If the customer has changed their mind after the product or
                service has been delivered.
              </li>
              <li className="mb-2">
                Delays in delivery caused by unforeseen circumstances beyond
                Kitovu's control, such as natural disasters, supply chain
                disruptions, or political instability.
              </li>
              <li className="mb-2">
                If the customer provides incorrect or incomplete information
                leading to an issue with delivery or service fulfillment.
              </li>
            </ul>
          </section>

          {/* Refund Process */}
          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-700 mb-3">
              4. Refund Process
            </h2>
            <ol className="list-decimal pl-6 text-gray-600">
              <li className="mb-2">
                <strong>Submit a Request:</strong> Contact Kitovu Technology
                Company’s customer support team within 7 days of receiving the
                product or service. The request should include the order number,
                reason for the refund, and any supporting documentation (e.g.,
                photos of damaged products).
              </li>
              <li className="mb-2">
                <strong>Evaluation:</strong> Upon receipt of the request, Kitovu
                will evaluate the claim and may request additional information.
                A response will be provided within 5 business days.
              </li>
              <li className="mb-2">
                <strong>Approval or Rejection:</strong> If approved, the refund
                will be processed within 10 business days. If rejected, an
                explanation will be provided to the customer.
              </li>
            </ol>
          </section>

          {/* Refund Methods */}
          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-700 mb-3">
              5. Refund Methods
            </h2>
            <p className="text-gray-600">
              Refunds will be issued via the original payment method used by the
              customer. This may include:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li className="mb-2">Bank transfer</li>
              <li className="mb-2">Mobile money transfer</li>
              <li className="mb-2">
                Payment to the customer's account on the relevant Kitovu
                platform (WeBuyAm, eProcure, etc.)
              </li>
            </ul>
          </section>

          {/* Partial Refunds */}
          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-700 mb-3">
              6. Partial Refunds
            </h2>
            <p className="text-gray-600">
              In cases where only part of the product or service is faulty or
              incomplete, Kitovu may issue a partial refund based on the
              proportion of the product/service that was not delivered as per
              the agreement.
            </p>
          </section>

          {/* Exchanges */}
          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-700 mb-3">
              7. Exchanges
            </h2>
            <p className="text-gray-600">
              Where applicable, customers may opt for a replacement or exchange
              instead of a refund. The exchange will be processed following the
              same guidelines as refunds.
            </p>
          </section>

          {/* Contact Information */}
          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-700 mb-3">
              8. Contact Information
            </h2>
            <p className="text-gray-600">
              For further assistance regarding refunds, customers can contact:
            </p>
            <ul className="list-none text-gray-600">
              <li className="mb-2">Kitovu Technology Company</li>
              <li className="mb-2">Email: info@kitovu.com.ng</li>
              <li className="mb-2">Phone: +2347035091865</li>
              <li className="mb-2">
                Business Address: Area, Iseyin Area, Iseyi Oyo.
              </li>
            </ul>
          </section>

          {/* Modifications to the Policy */}
          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-700 mb-3">
              9. Modifications to the Policy
            </h2>
            <p className="text-gray-600">
              Kitovu Technology Company reserves the right to modify this refund
              policy at any time. Changes will be communicated to customers via
              email or the company’s website.
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RefundPolicy;
