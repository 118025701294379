import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import ScrollToTop from "../Components/ScrollToTop";

const PrivacyPolicy = () => {
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <div className="bg-gray-100 py-[7rem]">
        <div className="bg-white text-gray-800 p-6 sm:p-10 max-w-4xl mx-auto rounded-lg shadow-md">
          <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4 text-center">
            Privacy Policy
          </h1>
          <p className="text-base sm:text-lg mb-6">
            Welcome to Kitovu Technology Company! Your privacy is important to
            us, and we are committed to protecting it. This privacy policy
            outlines how we collect, use, and safeguard your personal
            information when you interact with our website and services.
          </p>
          <section className="mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-900 mb-3">
              1. Information We Collect
            </h2>
            <p className="mb-4">
              We collect information to improve our website, provide our
              services, and communicate with you. The types of information we
              collect may include:
            </p>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                <span className="font-semibold">Personal Information:</span>{" "}
                Such as your name, email address, phone number, and any other
                information you voluntarily provide when filling out forms on
                our website.
              </li>
              <li>
                <span className="font-semibold">Usage Data:</span> Information
                automatically collected when you access our website, including
                IP address, browser type, pages visited, and other usage
                information.
              </li>
              <li>
                <span className="font-semibold">
                  Cookies and Tracking Technology:
                </span>{" "}
                We use cookies and similar technologies to track your activity
                on our website and personalize your experience. You can control
                cookie settings through your browser preferences.
              </li>
            </ul>
          </section>
          <section className="mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-900 mb-3">
              2. How We Use Your Information
            </h2>
            <p className="mb-4">
              We may use the information we collect for purposes such as:
            </p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Providing and improving our services</li>
              <li>Responding to your inquiries or requests</li>
              <li>
                Sending updates, newsletters, or marketing communications (only
                if you have opted in)
              </li>
              <li>
                Analyzing and improving website performance and user experience
              </li>
              <li>
                Protecting our website and services against fraud and
                unauthorized access
              </li>
            </ul>
          </section>
          <footer className="border-t border-gray-300 pt-4 mt-6">
            <p className="text-sm sm:text-base">
              Related Practices and Information:
            </p>
            <ul className="list-disc ml-4">
              <li>
                <a href="/refund-policy" className="hover:underline">
                  Refund Policy
                </a>
              </li>
              <li>
                <a href="terms-conditions" className="hover:underline">
                  Terms and Conditions
                </a>
              </li>
            </ul>
          </footer>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
